.footer {
  background-color: var(--bleu-2);
  height: 308px;
  position: relative;
  width: 1440px;
}

.footer .overlap {
  height: 70px;
  left: 207px;
  position: absolute;
  top: 60px;
  width: 179px;
}

.footer .group {
  height: 55px;
  left: 0;
  position: absolute;
  top: 0;
  width: 149px;
}

.footer .overlap-group-wrapper {
  height: 53px;
  left: 0;
  position: absolute;
  top: 0;
  width: 53px;
}

.footer .overlap-group {
  height: 53px;
  position: relative;
}

.footer .ellipse {
  background-color: #0d2042;
  border-radius: 13.23px/13.17px;
  height: 26px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 26px;
}

.footer .div {
  background-color: #2356ad;
  border-radius: 13.23px/13.17px;
  height: 26px;
  left: 13px;
  position: absolute;
  top: 26px;
  width: 26px;
}

.footer .ellipse-2 {
  background-color: #4a8cff;
  border-radius: 13.17px/13.23px;
  height: 26px;
  left: 27px;
  position: absolute;
  top: 13px;
  transform: rotate(90deg);
  width: 26px;
}

.footer .ellipse-3 {
  background-color: #13236a;
  border-radius: 13.17px/13.35px;
  height: 27px;
  left: 0;
  position: absolute;
  top: 13px;
  transform: rotate(90deg);
  width: 26px;
}

.footer .mask-group {
  height: 26px;
  left: 13px;
  position: absolute;
  top: 26px;
  width: 26px;
}

.footer .img {
  height: 26px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 26px;
}

.footer .dit-rit {
  color: #ffffff;
  font-family: "Nunito Sans", Helvetica;
  font-size: 32px;
  font-weight: 400;
  height: 52px;
  left: 62px;
  letter-spacing: 0;
  line-height: 51.7px;
  position: absolute;
  top: 3px;
  white-space: nowrap;
  width: 85px;
}

.footer .text-wrapper {
  font-weight: 800;
}

.footer .span {
  font-weight: 200;
}

.footer .p {
  color: #ffffff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  left: 62px;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  top: 44px;
  white-space: nowrap;
}

.footer .github {
  height: 30px !important;
  left: 306px !important;
  position: absolute !important;
  top: 156px !important;
  width: 30px !important;
}

.footer .discord-instance {
  left: 256px !important;
  position: absolute !important;
  top: 156px !important;
}

.footer .zulip-1 {
  height: 30px !important;
  left: 306px !important;
  position: absolute !important;
  top: 156px !important;
  width: 30px !important;
}

.footer .linked-in-instance {
  left: 356px !important;
  position: absolute !important;
  top: 156px !important;
}

.footer .vector {
  height: 145px;
  left: 491px;
  position: absolute;
  top: 60px;
  width: 2px;
}

.footer .text-wrapper-2 {
  color: var(--blanc);
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 622px;
  letter-spacing: 0;
  line-height: normal;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  top: 261px;
}

.footer .text-wrapper-3 {
  color: #ffffff;
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 989px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 127px;
}

.footer .text-wrapper-4 {
  color: #ffffff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  left: 630px;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  text-align: center;
  top: 127px;
  white-space: nowrap;
}

.footer .text-wrapper-5 {
  color: #ffffff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  left: 630px;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  text-align: center;
  top: 162px;
  white-space: nowrap;
}

.footer .text-wrapper-6 {
  color: #ffffff;
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 989px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 166px;
}

.footer .text-wrapper-7 {
  color: #ffffff;
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 989px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 85px;
}

.footer .text-wrapper-8 {
  color: #ffffff;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  left: 630px;
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  position: absolute;
  text-align: center;
  top: 75px;
  white-space: nowrap;
}
