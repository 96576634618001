.page-produit {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.page-produit .div-2 {
  background-color: var(--blanc);
  height: 2168px;
  position: relative;
  width: 1440px;
}

.page-produit .overlap-2 {
  height: 614px;
  left: 98px;
  position: absolute;
  top: 178px;
  width: 1154px;
}

.page-produit .overlap-3 {
  height: 598px;
  left: 1px;
  position: absolute;
  top: 16px;
  width: 1153px;
}

.page-produit .ellipse-5 {
  background-color: var(--bleu-3);
  border-radius: 16.5px;
  height: 33px;
  left: 596px;
  position: absolute;
  top: 524px;
  width: 33px;
}

.page-produit .text-wrapper-13 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  height: 156px;
  left: 0;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  top: 161px;
  width: 604px;
}

.page-produit .text-wrapper-14 {
  color: #000000;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  height: 32px;
  left: 890px;
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  position: absolute;
  text-align: center;
  top: 566px;
  white-space: nowrap;
}

.page-produit .text-wrapper-15 {
  color: #000000;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  height: 32px;
  left: 550px;
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  position: absolute;
  top: 566px;
  white-space: nowrap;
}

.page-produit .vector-2 {
  height: 327px;
  left: 725px;
  position: absolute;
  top: 45px;
  width: 428px;
}

.page-produit .power {
  height: 560px;
  left: 589px;
  position: absolute;
  top: 11px;
  width: 343px;
}

.page-produit .vector-3 {
  height: 85px;
  left: 788px;
  position: absolute;
  top: 0;
  width: 41px;
}

.page-produit .vector-4 {
  height: 20px;
  left: 869px;
  position: absolute;
  top: 198px;
  width: 24px;
}

.page-produit .vector-5 {
  height: 135px;
  left: 797px;
  position: absolute;
  top: 110px;
  width: 95px;
}

.page-produit .vector-6 {
  height: 35px;
  left: 982px;
  position: absolute;
  top: 149px;
  width: 28px;
}

.page-produit .vector-7 {
  height: 173px;
  left: 901px;
  position: absolute;
  top: 200px;
  width: 156px;
}

.page-produit .vector-8 {
  height: 124px;
  left: 911px;
  position: absolute;
  top: 120px;
  width: 91px;
}

.page-produit .vector-9 {
  height: 73px;
  left: 928px;
  position: absolute;
  top: 91px;
  width: 85px;
}

.page-produit .vector-10 {
  height: 80px;
  left: 939px;
  position: absolute;
  top: 65px;
  width: 82px;
}

.page-produit .vector-11 {
  height: 6px;
  left: 922px;
  position: absolute;
  top: 161px;
  width: 30px;
}

.page-produit .group-2 {
  height: 208px;
  left: 839px;
  position: absolute;
  top: 151px;
  width: 212px;
}

.page-produit .vector-12 {
  height: 74px;
  left: 828px;
  position: absolute;
  top: 132px;
  width: 32px;
}

.page-produit .vector-13 {
  height: 28px;
  left: 820px;
  position: absolute;
  top: 109px;
  width: 31px;
}

.page-produit .vector-14 {
  height: 20px;
  left: 809px;
  position: absolute;
  top: 121px;
  width: 12px;
}

.page-produit .vector-15 {
  height: 18px;
  left: 801px;
  position: absolute;
  top: 128px;
  width: 11px;
}

.page-produit .vector-16 {
  height: 105px;
  left: 797px;
  position: absolute;
  top: 140px;
  width: 89px;
}

.page-produit .vector-17 {
  height: 51px;
  left: 888px;
  position: absolute;
  top: 101px;
  width: 90px;
}

.page-produit .vector-18 {
  height: 44px;
  left: 887px;
  position: absolute;
  top: 102px;
  width: 76px;
}

.page-produit .vector-19 {
  height: 41px;
  left: 892px;
  position: absolute;
  top: 102px;
  width: 28px;
}

.page-produit .vector-20 {
  height: 43px;
  left: 902px;
  position: absolute;
  top: 102px;
  width: 26px;
}

.page-produit .group-3 {
  height: 86px;
  left: 788px;
  position: absolute;
  top: 1px;
  width: 42px;
}

.page-produit .vector-21 {
  height: 85px;
  left: 820px;
  position: absolute;
  top: 9px;
  width: 41px;
}

.page-produit .group-4 {
  height: 86px;
  left: 820px;
  position: absolute;
  top: 10px;
  width: 42px;
}

.page-produit .vector-22 {
  height: 56px;
  left: 854px;
  position: absolute;
  top: 38px;
  width: 41px;
}

.page-produit .group-5 {
  height: 57px;
  left: 856px;
  position: absolute;
  top: 39px;
  width: 42px;
}

.page-produit .overlap-wrapper {
  height: 55px;
  left: 902px;
  position: absolute;
  top: 507px;
  width: 77px;
}

.page-produit .overlap-4 {
  height: 57px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 79px;
}

.page-produit .overlap-5 {
  height: 48px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 77px;
}

.page-produit .rectangle {
  background-color: var(--bleu-3);
  border-radius: 2px;
  height: 40px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 62px;
}

.page-produit .rectangle-2 {
  border: 2px solid;
  border-color: #000000;
  border-radius: 2px;
  height: 39px;
  left: 7px;
  position: absolute;
  top: 2px;
  width: 63px;
}

.page-produit .rectangle-3 {
  border: 2px solid;
  border-color: #000000;
  border-radius: 2px;
  height: 36px;
  left: 10px;
  position: absolute;
  top: 5px;
  width: 57px;
}

.page-produit .rectangle-4 {
  border: 2px solid;
  border-color: #000000;
  border-radius: 4px;
  height: 9px;
  left: 0;
  position: absolute;
  top: 39px;
  width: 77px;
}

.page-produit .check-circle {
  height: 22px;
  left: 26px;
  position: absolute;
  top: 13px;
  width: 22px;
}

.page-produit .overlap-6 {
  height: 12px;
  left: 68px;
  position: absolute;
  top: 0;
  width: 11px;
}

.page-produit .vector-23 {
  height: 5px;
  left: 5px;
  position: absolute;
  top: 8px;
  width: 7px;
}

.page-produit .vector-24 {
  height: 6px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 5px;
}

.page-produit .vector-25 {
  height: 7px;
  left: 0;
  position: absolute;
  top: 0;
  width: 3px;
}

.page-produit .ogree-offline-graph {
  color: var(--bleu-2);
  font-family: "Nunito Sans", Helvetica;
  font-size: 46px;
  font-weight: 400;
  height: 128px;
  left: 0;
  letter-spacing: 0;
  line-height: 73.6px;
  position: absolute;
  top: 38px;
  width: 499px;
}

.page-produit .text-wrapper-16 {
  font-family: var(--titre-h2-font-family);
  font-size: var(--titre-h2-font-size);
  font-style: var(--titre-h2-font-style);
  font-weight: var(--titre-h2-font-weight);
  letter-spacing: var(--titre-h2-letter-spacing);
  line-height: var(--titre-h2-line-height);
}

.page-produit .text-wrapper-17 {
  font-family: var(--titre-h3-font-family);
  font-size: var(--titre-h3-font-size);
  font-style: var(--titre-h3-font-style);
  font-weight: var(--titre-h3-font-weight);
  letter-spacing: var(--titre-h3-letter-spacing);
  line-height: var(--titre-h3-line-height);
}

.page-produit .text-wrapper-18 {
  color: #000000;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  height: 32px;
  left: 256px;
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  position: absolute;
  top: 582px;
  white-space: nowrap;
}

.page-produit .bouton-1 {
  background-color: var(--bleu-2) !important;
  left: 68px !important;
  position: absolute !important;
  top: 378px !important;
}

.page-produit .bouton-instance {
  color: var(--blanc) !important;
}

.page-produit .frame {
  align-items: center;
  display: inline-flex;
  gap: 10px;
  left: 270px;
  position: absolute;
  top: 388px;
}

.page-produit .text-wrapper-19 {
  color: var(--bleu-3);
  font-family: var(--texte-regular-bold-font-family);
  font-size: var(--texte-regular-bold-font-size);
  font-style: var(--texte-regular-bold-font-style);
  font-weight: var(--texte-regular-bold-font-weight);
  letter-spacing: var(--texte-regular-bold-letter-spacing);
  line-height: var(--texte-regular-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.page-produit .arrow-right-3 {
  height: 21px !important;
  position: relative !important;
  width: 21px !important;
}

.page-produit .github-1 {
  height: 46px !important;
  left: 2px !important;
  position: absolute !important;
  top: 378px !important;
  width: 46px !important;
}

.footer .github {
  height: 30px !important;
  left: 306px !important;
  position: absolute !important;
  top: 156px !important;
  width: 30px !important;
}

.page-produit .text-wrapper-20 {
  color: var(--bleu-3);
  font-family: "Nunito Sans", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 38px;
  left: 2px;
  letter-spacing: 0;
  line-height: 38.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.page-produit .overlap-7 {
  height: 27px;
  left: 281px;
  position: absolute;
  top: 546px;
  width: 44px;
}

.page-produit .rectangle-5 {
  background-color: var(--bleu-3);
  border-radius: 1px;
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.page-produit .rectangle-6 {
  border: 2px solid;
  border-color: var(--bleu-2);
  height: 14px;
  left: 2px;
  position: absolute;
  top: 1px;
  width: 42px;
}

.page-produit .rectangle-7 {
  border: 2px solid;
  border-color: var(--bleu-2);
  height: 14px;
  left: 2px;
  position: absolute;
  top: 13px;
  width: 42px;
}

.page-produit .vector-26 {
  height: 2px;
  left: 7px;
  position: absolute;
  top: 7px;
  width: 22px;
}

.page-produit .vector-27 {
  height: 2px;
  left: 31px;
  position: absolute;
  top: 7px;
  width: 8px;
}

.page-produit .vector-28 {
  height: 2px;
  left: 31px;
  position: absolute;
  top: 19px;
  width: 8px;
}

.page-produit .vector-29 {
  height: 2px;
  left: 7px;
  position: absolute;
  top: 19px;
  width: 22px;
}

.page-produit .overlap-8 {
  height: 32px;
  left: 291px;
  position: absolute;
  top: 514px;
  width: 33px;
}

.page-produit .eye {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 26px;
}

.page-produit .vector-30 {
  height: 5px;
  left: 24px;
  position: absolute;
  top: 17px;
  width: 6px;
}

.page-produit .vector-31 {
  height: 3px;
  left: 26px;
  position: absolute;
  top: 14px;
  width: 7px;
}

.page-produit .vector-32 {
  height: 10px;
  left: 12px;
  position: absolute;
  top: 22px;
  width: 2px;
}

.page-produit .overlap-9 {
  height: 1652px;
  left: 0;
  position: absolute;
  top: 916px;
  width: 1440px;
}

.page-produit .rectangle-8 {
  background-color: var(--bleu-2);
  border-radius: 5px;
  height: 3055px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1440px;
}

.page-produit .footer-instance {
  left: 0 !important;
  position: absolute !important;
  top: 2980px !important;
}

.page-produit .text-wrapper-21 {
  color: var(--blanc);
  font-family: var(--titre-h2-font-family);
  font-size: var(--titre-h2-font-size);
  font-style: var(--titre-h2-font-style);
  font-weight: var(--titre-h2-font-weight);
  height: 74px;
  left: 99px;
  letter-spacing: var(--titre-h2-letter-spacing);
  line-height: var(--titre-h2-line-height);
  position: absolute;
  top: 542px;
  width: 499px;
}

.page-produit .text-wrapper-22 {
  color: var(--blanc);
  font-family: var(--titre-h2-font-family);
  font-size: var(--titre-h2-font-size);
  font-style: var(--titre-h2-font-style);
  font-weight: var(--titre-h2-font-weight);
  height: 74px;
  left: 99px;
  letter-spacing: var(--titre-h2-letter-spacing);
  line-height: var(--titre-h2-line-height);
  position: absolute;
  top: 1050px;
  width: 699px;
}
.page-produit .text-wrapper-22-subtitle {
  color: var(--blanc);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  height: 54px;
  left: 205px;
  position: absolute;
  top: 1140px;
  width: 699px;
}

.page-produit .text-wrapper-22-subtitle-2 {
  color: var(--blanc);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  height: 54px;
  left: 205px;
  position: absolute;
  top: 1830px;
  width: 699px;
}

.page-produit .text-wrapper-22-subtitle-3 {
  color: var(--blanc);
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  height: 54px;
  left: 180px;
  position: absolute;
  top: 2550px;
  width: 275px;
}

.page-produit .text-wrapper-22-subtitle-4 {
  color: #ffffff;
  /* text-align: justify; */
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  height: 54px;
  left: 180px;
  position: absolute;
  top: 2650px;
  width: 265px;
}

.page-produit .text-wrapper-23 {
  color: var(--bleu-3);
  font-family: var(--texte-regular-bold-font-family);
  font-size: var(--texte-regular-bold-font-size);
  font-style: var(--texte-regular-bold-font-style);
  font-weight: var(--texte-regular-bold-font-weight);
  height: 26px;
  left: 839px;
  letter-spacing: var(--texte-regular-bold-letter-spacing);
  line-height: var(--texte-regular-bold-line-height);
  position: absolute;
  text-align: right;
  top: 579px;
  width: 499px;
}

.page-produit .group-wrapper {
  height: 290px;
  left: 214px;
  position: absolute;
  top: 620px;
  width: 1028px;
}

.page-produit .group-6 {
  height: 290px;
  position: relative;
  width: 1032px;
}

.page-produit .text-wrapper-24 {
  color: var(--blanc);
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  height: 32px;
  left: 0;
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  position: absolute;
  top: 32px;
  width: 498px;
}

.page-produit .OUI-notre-collectif {
  color: var(--blanc);
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 182px;
  left: 0;
  letter-spacing: 0;
  line-height: 25.6px;
  position: absolute;
  top: 75px;
  width: 498px;
}

.page-produit .text-wrapper-25 {
  font-family: var(--texte-regular-bold-font-family);
  font-size: var(--texte-regular-bold-font-size);
  font-style: var(--texte-regular-bold-font-style);
  font-weight: var(--texte-regular-bold-font-weight);
  letter-spacing: var(--texte-regular-bold-letter-spacing);
  line-height: var(--texte-regular-bold-line-height);
}

.page-produit .text-wrapper-26 {
  color: #ffffff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
}

.page-produit .rectangle-9 {
  height: 300px;
  left: 530px;
  position: absolute;
  top: 0;
  width: 500px;
}

.page-produit .carte-event-instance {
  left: 100px !important;
  position: absolute !important;
  top: 637px !important;
}

.page-produit .design-component-instance-node {
  left: 525px !important;
  position: absolute !important;
  top: 637px !important;
}

.page-produit .carte-event-2 {
  left: 950px !important;
  position: absolute !important;
  top: 637px !important;
}

.page-produit .carte-collab-instance {
  left: 206px !important;
  position: absolute !important;
  top: 1074px !important;
}

.page-produit .carte-collab-2 {
  left: 35px !important;
}

.page-produit .carte-collab-3 {
  left: 43px !important;
}

.page-produit .carte-collab-4 {
  left: 418px !important;
  position: absolute !important;
  top: 1074px !important;
}

.page-produit .carte-collab-5 {
  left: 180px !important;
  position: absolute !important;
  top: 1210px !important;
}

.page-produit .video-2 {
  left: 180px !important;
  position: absolute !important;
  top: 1890px !important;
}

.page-produit .video-3 {
  left: 467px !important;
  position: absolute !important;
  top: 2545px !important;
}

.page-produit .carte-collab-6 {
  left: 20px !important;
}

.page-produit .carte-collab-7 {
  left: 842px !important;
  position: absolute !important;
  top: 1074px !important;
}

.page-produit .carte-collab-8 {
  left: 27px !important;
}

.page-produit .carte-collab-9 {
  left: 24px !important;
}

.page-produit .carte-collab-10 {
  left: 1054px !important;
  position: absolute !important;
  top: 1074px !important;
}

.page-produit .carte-collab-11 {
  left: 34px !important;
}

.page-produit .group-7 {
  height: 16px;
  left: 939px;
  position: absolute;
  top: 930px;
  width: 107px;
}

.page-produit .arrow-left {
  height: 16px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 16px !important;
}

.page-produit .arrow-left-instance {
  height: 16px !important;
  left: 91px !important;
  position: absolute !important;
  top: 0 !important;
  width: 16px !important;
}

.page-produit .ellipse-6 {
  background-color: #d9d9d9;
  border-radius: 3.5px;
  height: 7px;
  left: 26px;
  position: absolute;
  top: 4px;
  width: 7px;
}

.page-produit .ellipse-7 {
  background-color: #d9d9d9;
  border-radius: 3.5px;
  height: 7px;
  left: 42px;
  opacity: 0.5;
  position: absolute;
  top: 4px;
  width: 7px;
}

.page-produit .ellipse-8 {
  background-color: #d9d9d9;
  border-radius: 3.5px;
  height: 7px;
  left: 58px;
  opacity: 0.5;
  position: absolute;
  top: 4px;
  width: 7px;
}

.page-produit .ellipse-9 {
  background-color: #d9d9d9;
  border-radius: 3.5px;
  height: 7px;
  left: 74px;
  opacity: 0.5;
  position: absolute;
  top: 4px;
  width: 7px;
}

.page-produit .text-wrapper-27 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  height: 52px;
  left: 294px;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  text-align: center;
  top: 803px;
  width: 214px;
}

.page-produit .text-wrapper-28 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  height: 52px;
  left: 932px;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  text-align: center;
  top: 803px;
  width: 214px;
}

.page-produit .text-wrapper-29 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  height: 26px;
  left: 577px;
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: absolute;
  text-align: center;
  top: 803px;
  width: 286px;
}

.page-produit .navbar {
  -webkit-backdrop-filter: blur(24px) brightness(100%);
  backdrop-filter: blur(24px) brightness(100%);
  background-color: #ffffffcc;
  height: 105px;
  position: fixed;
  top: 0;
  width: 1440px;
  z-index: 100;
} 

.page-produit .group-8 {
  height: 55px;
  left: 60px;
  position: absolute;
  top: 28px;
  width: 147px;
}

.page-produit .text-wrapper-30 {
  color: var(--bleu-2);
  font-family: "Nunito Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 100px;
  left: 262px;
  letter-spacing: 0;
  line-height: 35.9px;
  position: absolute;
  top: 38px;
  width: 250px;
}

.page-produit .text-wrapper-31 {
  color: var(--bleu-2);
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 49px;
  left: 679px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 28px;
  width: 103px;
}

.page-produit .text-wrapper-32 {
  color: var(--bleu-2);
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 49px;
  left: 1154px;
  letter-spacing: 0;
  line-height: 25.9px;
  position: absolute;
  top: 40px;
  width: 65px;
}

.page-produit .github-6 {
  height: 30px !important;
  left: 1250px !important;
  position: absolute !important;
  top: 38px !important;
  width: 30px !important;
}

.page-produit .language {
  /* height: 30px !important; */
  left: 1320px !important;
  position: absolute !important;
  top: 43px !important;
  /* width: 30px !important; */
}

.page-produit .frame-2 {
  align-items: center;
  display: inline-flex;
  gap: 5px;
  height: 49px;
  left: 468px;
  position: absolute;
  top: 29px;
}

.page-produit .text-wrapper-33 {
  color: var(--bleu-2);
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.page-produit .chevron-down {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.page-produit .fiche-produit-instance {
  left: 524px !important;
  position: absolute !important;
  top: -30px !important;
}

.page-produit .fiche-produit-2 {
  background-color: unset !important;
  border-radius: unset !important;
  left: 40px !important;
  position: absolute !important;
  top: 200px !important;
}

.page-produit .fiche-produit-3 {
  background-color: unset !important;
  border-radius: unset !important;
  left: 207px !important;
  position: absolute !important;
  top: 200px !important;
}

.page-produit .fiche-produit-4 {
  left: 2650px !important;
  top: -1087px !important;
}

.page-produit .fiche-produit-5 {
  left: 100px !important;
  position: absolute !important;
  top: -30px !important;
}

.page-produit .fiche-produit-6 {
  background-color: var(--bleu-5) !important;
}

.page-produit .fiche-produit-7 {
  background-color: unset !important;
  border-radius: unset !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.page-produit .fiche-produit-8 {
  background-color: unset !important;
  border-radius: unset !important;
  left: 167px !important;
  position: absolute !important;
  top: 0 !important;
}

.page-produit .fiche-produit-9 {
  left: 948px !important;
  position: absolute !important;
  top: -30px !important;
}