.bouton {
  background-color: #fff;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 30px;
  display: inline-flex;
  position: relative;
}

.bouton .en-savoir-plus {
  color: var(--bleu-2);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 800;
  line-height: 25.9px;
  position: relative;
}

.discord {
  background-image: url("exclude-2.83b8a856.svg");
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
}

.linked-in {
  background-image: url("exclude-3.1d94868b.svg");
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
}

.footer {
  background-color: var(--bleu-2);
  width: 1440px;
  height: 308px;
  position: relative;
}

.footer .overlap {
  width: 179px;
  height: 70px;
  position: absolute;
  top: 60px;
  left: 207px;
}

.footer .group {
  width: 149px;
  height: 55px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer .overlap-group-wrapper {
  width: 53px;
  height: 53px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer .overlap-group {
  height: 53px;
  position: relative;
}

.footer .ellipse {
  background-color: #0d2042;
  border-radius: 13.23px / 13.17px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 13px;
}

.footer .div {
  background-color: #2356ad;
  border-radius: 13.23px / 13.17px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 26px;
  left: 13px;
}

.footer .ellipse-2 {
  background-color: #4a8cff;
  border-radius: 13.17px / 13.23px;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 13px;
  left: 27px;
  transform: rotate(90deg);
}

.footer .ellipse-3 {
  background-color: #13236a;
  border-radius: 13.17px / 13.35px;
  width: 26px;
  height: 27px;
  position: absolute;
  top: 13px;
  left: 0;
  transform: rotate(90deg);
}

.footer .mask-group {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 26px;
  left: 13px;
}

.footer .img {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 13px;
}

.footer .dit-rit {
  color: #fff;
  letter-spacing: 0;
  white-space: nowrap;
  width: 85px;
  height: 52px;
  font-family: Nunito Sans, Helvetica;
  font-size: 32px;
  font-weight: 400;
  line-height: 51.7px;
  position: absolute;
  top: 3px;
  left: 62px;
}

.footer .text-wrapper {
  font-weight: 800;
}

.footer .span {
  font-weight: 200;
}

.footer .p {
  color: #fff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  white-space: nowrap;
  position: absolute;
  top: 44px;
  left: 62px;
}

.footer .discord-instance {
  position: absolute !important;
  top: 156px !important;
  left: 256px !important;
}

.footer .zulip-1 {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 156px !important;
  left: 306px !important;
}

.footer .linked-in-instance {
  position: absolute !important;
  top: 156px !important;
  left: 356px !important;
}

.footer .vector {
  width: 2px;
  height: 145px;
  position: absolute;
  top: 60px;
  left: 491px;
}

.footer .text-wrapper-2 {
  color: var(--blanc);
  letter-spacing: 0;
  opacity: .5;
  text-align: center;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 261px;
  left: 622px;
}

.footer .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 127px;
  left: 989px;
}

.footer .text-wrapper-4 {
  color: #fff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 127px;
  left: 630px;
}

.footer .text-wrapper-5 {
  color: #fff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 162px;
  left: 630px;
}

.footer .text-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 166px;
  left: 989px;
}

.footer .text-wrapper-7 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 85px;
  left: 989px;
}

.footer .text-wrapper-8 {
  color: #fff;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: 75px;
  left: 630px;
}

.page-produit {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.page-produit .div-2 {
  background-color: var(--blanc);
  width: 1440px;
  height: 2168px;
  position: relative;
}

.page-produit .overlap-2 {
  width: 1154px;
  height: 614px;
  position: absolute;
  top: 178px;
  left: 98px;
}

.page-produit .overlap-3 {
  width: 1153px;
  height: 598px;
  position: absolute;
  top: 16px;
  left: 1px;
}

.page-produit .ellipse-5 {
  background-color: var(--bleu-3);
  border-radius: 16.5px;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 524px;
  left: 596px;
}

.page-produit .text-wrapper-13 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  width: 604px;
  height: 156px;
  position: absolute;
  top: 161px;
  left: 0;
}

.page-produit .text-wrapper-14 {
  color: #000;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  text-align: center;
  white-space: nowrap;
  height: 32px;
  position: absolute;
  top: 566px;
  left: 890px;
}

.page-produit .text-wrapper-15 {
  color: #000;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  white-space: nowrap;
  height: 32px;
  position: absolute;
  top: 566px;
  left: 550px;
}

.page-produit .vector-2 {
  width: 428px;
  height: 327px;
  position: absolute;
  top: 45px;
  left: 725px;
}

.page-produit .power {
  width: 343px;
  height: 560px;
  position: absolute;
  top: 11px;
  left: 589px;
}

.page-produit .vector-3 {
  width: 41px;
  height: 85px;
  position: absolute;
  top: 0;
  left: 788px;
}

.page-produit .vector-4 {
  width: 24px;
  height: 20px;
  position: absolute;
  top: 198px;
  left: 869px;
}

.page-produit .vector-5 {
  width: 95px;
  height: 135px;
  position: absolute;
  top: 110px;
  left: 797px;
}

.page-produit .vector-6 {
  width: 28px;
  height: 35px;
  position: absolute;
  top: 149px;
  left: 982px;
}

.page-produit .vector-7 {
  width: 156px;
  height: 173px;
  position: absolute;
  top: 200px;
  left: 901px;
}

.page-produit .vector-8 {
  width: 91px;
  height: 124px;
  position: absolute;
  top: 120px;
  left: 911px;
}

.page-produit .vector-9 {
  width: 85px;
  height: 73px;
  position: absolute;
  top: 91px;
  left: 928px;
}

.page-produit .vector-10 {
  width: 82px;
  height: 80px;
  position: absolute;
  top: 65px;
  left: 939px;
}

.page-produit .vector-11 {
  width: 30px;
  height: 6px;
  position: absolute;
  top: 161px;
  left: 922px;
}

.page-produit .group-2 {
  width: 212px;
  height: 208px;
  position: absolute;
  top: 151px;
  left: 839px;
}

.page-produit .vector-12 {
  width: 32px;
  height: 74px;
  position: absolute;
  top: 132px;
  left: 828px;
}

.page-produit .vector-13 {
  width: 31px;
  height: 28px;
  position: absolute;
  top: 109px;
  left: 820px;
}

.page-produit .vector-14 {
  width: 12px;
  height: 20px;
  position: absolute;
  top: 121px;
  left: 809px;
}

.page-produit .vector-15 {
  width: 11px;
  height: 18px;
  position: absolute;
  top: 128px;
  left: 801px;
}

.page-produit .vector-16 {
  width: 89px;
  height: 105px;
  position: absolute;
  top: 140px;
  left: 797px;
}

.page-produit .vector-17 {
  width: 90px;
  height: 51px;
  position: absolute;
  top: 101px;
  left: 888px;
}

.page-produit .vector-18 {
  width: 76px;
  height: 44px;
  position: absolute;
  top: 102px;
  left: 887px;
}

.page-produit .vector-19 {
  width: 28px;
  height: 41px;
  position: absolute;
  top: 102px;
  left: 892px;
}

.page-produit .vector-20 {
  width: 26px;
  height: 43px;
  position: absolute;
  top: 102px;
  left: 902px;
}

.page-produit .group-3 {
  width: 42px;
  height: 86px;
  position: absolute;
  top: 1px;
  left: 788px;
}

.page-produit .vector-21 {
  width: 41px;
  height: 85px;
  position: absolute;
  top: 9px;
  left: 820px;
}

.page-produit .group-4 {
  width: 42px;
  height: 86px;
  position: absolute;
  top: 10px;
  left: 820px;
}

.page-produit .vector-22 {
  width: 41px;
  height: 56px;
  position: absolute;
  top: 38px;
  left: 854px;
}

.page-produit .group-5 {
  width: 42px;
  height: 57px;
  position: absolute;
  top: 39px;
  left: 856px;
}

.page-produit .overlap-wrapper {
  width: 77px;
  height: 55px;
  position: absolute;
  top: 507px;
  left: 902px;
}

.page-produit .overlap-4 {
  width: 79px;
  height: 57px;
  position: relative;
  top: -1px;
  left: -1px;
}

.page-produit .overlap-5 {
  width: 77px;
  height: 48px;
  position: absolute;
  top: 9px;
  left: 0;
}

.page-produit .rectangle {
  background-color: var(--bleu-3);
  border-radius: 2px;
  width: 62px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 4px;
}

.page-produit .rectangle-2 {
  border: 2px solid #000;
  border-radius: 2px;
  width: 63px;
  height: 39px;
  position: absolute;
  top: 2px;
  left: 7px;
}

.page-produit .rectangle-3 {
  border: 2px solid #000;
  border-radius: 2px;
  width: 57px;
  height: 36px;
  position: absolute;
  top: 5px;
  left: 10px;
}

.page-produit .rectangle-4 {
  border: 2px solid #000;
  border-radius: 4px;
  width: 77px;
  height: 9px;
  position: absolute;
  top: 39px;
  left: 0;
}

.page-produit .check-circle {
  width: 22px;
  height: 22px;
  position: absolute;
  top: 13px;
  left: 26px;
}

.page-produit .overlap-6 {
  width: 11px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 68px;
}

.page-produit .vector-23 {
  width: 7px;
  height: 5px;
  position: absolute;
  top: 8px;
  left: 5px;
}

.page-produit .vector-24 {
  width: 5px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 3px;
}

.page-produit .vector-25 {
  width: 3px;
  height: 7px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-produit .ogree-offline-graph {
  color: var(--bleu-2);
  letter-spacing: 0;
  width: 499px;
  height: 128px;
  font-family: Nunito Sans, Helvetica;
  font-size: 46px;
  font-weight: 400;
  line-height: 73.6px;
  position: absolute;
  top: 38px;
  left: 0;
}

.page-produit .text-wrapper-16 {
  font-family: var(--titre-h2-font-family);
  font-size: var(--titre-h2-font-size);
  font-style: var(--titre-h2-font-style);
  font-weight: var(--titre-h2-font-weight);
  letter-spacing: var(--titre-h2-letter-spacing);
  line-height: var(--titre-h2-line-height);
}

.page-produit .text-wrapper-17 {
  font-family: var(--titre-h3-font-family);
  font-size: var(--titre-h3-font-size);
  font-style: var(--titre-h3-font-style);
  font-weight: var(--titre-h3-font-weight);
  letter-spacing: var(--titre-h3-letter-spacing);
  line-height: var(--titre-h3-line-height);
}

.page-produit .text-wrapper-18 {
  color: #000;
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  white-space: nowrap;
  height: 32px;
  position: absolute;
  top: 582px;
  left: 256px;
}

.page-produit .bouton-1 {
  background-color: var(--bleu-2) !important;
  position: absolute !important;
  top: 378px !important;
  left: 68px !important;
}

.page-produit .bouton-instance {
  color: var(--blanc) !important;
}

.page-produit .frame {
  align-items: center;
  gap: 10px;
  display: inline-flex;
  position: absolute;
  top: 388px;
  left: 270px;
}

.page-produit .text-wrapper-19 {
  color: var(--bleu-3);
  font-family: var(--texte-regular-bold-font-family);
  font-size: var(--texte-regular-bold-font-size);
  font-style: var(--texte-regular-bold-font-style);
  font-weight: var(--texte-regular-bold-font-weight);
  letter-spacing: var(--texte-regular-bold-letter-spacing);
  line-height: var(--texte-regular-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.page-produit .arrow-right-3 {
  width: 21px !important;
  height: 21px !important;
  position: relative !important;
}

.page-produit .github-1 {
  width: 46px !important;
  height: 46px !important;
  position: absolute !important;
  top: 378px !important;
  left: 2px !important;
}

.footer .github {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 156px !important;
  left: 306px !important;
}

.page-produit .text-wrapper-20 {
  color: var(--bleu-3);
  letter-spacing: 0;
  white-space: nowrap;
  height: 38px;
  font-family: Nunito Sans, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 38.4px;
  position: absolute;
  top: 0;
  left: 2px;
}

.page-produit .overlap-7 {
  width: 44px;
  height: 27px;
  position: absolute;
  top: 546px;
  left: 281px;
}

.page-produit .rectangle-5 {
  background-color: var(--bleu-3);
  border-radius: 1px;
  width: 40px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-produit .rectangle-6 {
  border: 2px solid;
  border-color: var(--bleu-2);
  width: 42px;
  height: 14px;
  position: absolute;
  top: 1px;
  left: 2px;
}

.page-produit .rectangle-7 {
  border: 2px solid;
  border-color: var(--bleu-2);
  width: 42px;
  height: 14px;
  position: absolute;
  top: 13px;
  left: 2px;
}

.page-produit .vector-26 {
  width: 22px;
  height: 2px;
  position: absolute;
  top: 7px;
  left: 7px;
}

.page-produit .vector-27 {
  width: 8px;
  height: 2px;
  position: absolute;
  top: 7px;
  left: 31px;
}

.page-produit .vector-28 {
  width: 8px;
  height: 2px;
  position: absolute;
  top: 19px;
  left: 31px;
}

.page-produit .vector-29 {
  width: 22px;
  height: 2px;
  position: absolute;
  top: 19px;
  left: 7px;
}

.page-produit .overlap-8 {
  width: 33px;
  height: 32px;
  position: absolute;
  top: 514px;
  left: 291px;
}

.page-produit .eye {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-produit .vector-30 {
  width: 6px;
  height: 5px;
  position: absolute;
  top: 17px;
  left: 24px;
}

.page-produit .vector-31 {
  width: 7px;
  height: 3px;
  position: absolute;
  top: 14px;
  left: 26px;
}

.page-produit .vector-32 {
  width: 2px;
  height: 10px;
  position: absolute;
  top: 22px;
  left: 12px;
}

.page-produit .overlap-9 {
  width: 1440px;
  height: 1652px;
  position: absolute;
  top: 916px;
  left: 0;
}

.page-produit .rectangle-8 {
  background-color: var(--bleu-2);
  border-radius: 5px;
  width: 1440px;
  height: 3055px;
  position: absolute;
  top: 0;
  left: 0;
}

.page-produit .footer-instance {
  position: absolute !important;
  top: 2980px !important;
  left: 0 !important;
}

.page-produit .text-wrapper-21 {
  color: var(--blanc);
  font-family: var(--titre-h2-font-family);
  font-size: var(--titre-h2-font-size);
  font-style: var(--titre-h2-font-style);
  font-weight: var(--titre-h2-font-weight);
  letter-spacing: var(--titre-h2-letter-spacing);
  line-height: var(--titre-h2-line-height);
  width: 499px;
  height: 74px;
  position: absolute;
  top: 542px;
  left: 99px;
}

.page-produit .text-wrapper-22 {
  color: var(--blanc);
  font-family: var(--titre-h2-font-family);
  font-size: var(--titre-h2-font-size);
  font-style: var(--titre-h2-font-style);
  font-weight: var(--titre-h2-font-weight);
  letter-spacing: var(--titre-h2-letter-spacing);
  line-height: var(--titre-h2-line-height);
  width: 699px;
  height: 74px;
  position: absolute;
  top: 1050px;
  left: 99px;
}

.page-produit .text-wrapper-22-subtitle {
  color: var(--blanc);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  width: 699px;
  height: 54px;
  position: absolute;
  top: 1140px;
  left: 205px;
}

.page-produit .text-wrapper-22-subtitle-2 {
  color: var(--blanc);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  width: 699px;
  height: 54px;
  position: absolute;
  top: 1830px;
  left: 205px;
}

.page-produit .text-wrapper-22-subtitle-3 {
  color: var(--blanc);
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  width: 275px;
  height: 54px;
  position: absolute;
  top: 2550px;
  left: 180px;
}

.page-produit .text-wrapper-22-subtitle-4 {
  color: #fff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  width: 265px;
  height: 54px;
  position: absolute;
  top: 2650px;
  left: 180px;
}

.page-produit .text-wrapper-23 {
  color: var(--bleu-3);
  font-family: var(--texte-regular-bold-font-family);
  font-size: var(--texte-regular-bold-font-size);
  font-style: var(--texte-regular-bold-font-style);
  font-weight: var(--texte-regular-bold-font-weight);
  letter-spacing: var(--texte-regular-bold-letter-spacing);
  line-height: var(--texte-regular-bold-line-height);
  text-align: right;
  width: 499px;
  height: 26px;
  position: absolute;
  top: 579px;
  left: 839px;
}

.page-produit .group-wrapper {
  width: 1028px;
  height: 290px;
  position: absolute;
  top: 620px;
  left: 214px;
}

.page-produit .group-6 {
  width: 1032px;
  height: 290px;
  position: relative;
}

.page-produit .text-wrapper-24 {
  color: var(--blanc);
  font-family: var(--titre-h4-font-family);
  font-size: var(--titre-h4-font-size);
  font-style: var(--titre-h4-font-style);
  font-weight: var(--titre-h4-font-weight);
  letter-spacing: var(--titre-h4-letter-spacing);
  line-height: var(--titre-h4-line-height);
  width: 498px;
  height: 32px;
  position: absolute;
  top: 32px;
  left: 0;
}

.page-produit .OUI-notre-collectif {
  color: var(--blanc);
  letter-spacing: 0;
  width: 498px;
  height: 182px;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  position: absolute;
  top: 75px;
  left: 0;
}

.page-produit .text-wrapper-25 {
  font-family: var(--texte-regular-bold-font-family);
  font-size: var(--texte-regular-bold-font-size);
  font-style: var(--texte-regular-bold-font-style);
  font-weight: var(--texte-regular-bold-font-weight);
  letter-spacing: var(--texte-regular-bold-letter-spacing);
  line-height: var(--texte-regular-bold-line-height);
}

.page-produit .text-wrapper-26 {
  color: #fff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
}

.page-produit .rectangle-9 {
  width: 500px;
  height: 300px;
  position: absolute;
  top: 0;
  left: 530px;
}

.page-produit .carte-event-instance {
  position: absolute !important;
  top: 637px !important;
  left: 100px !important;
}

.page-produit .design-component-instance-node {
  position: absolute !important;
  top: 637px !important;
  left: 525px !important;
}

.page-produit .carte-event-2 {
  position: absolute !important;
  top: 637px !important;
  left: 950px !important;
}

.page-produit .carte-collab-instance {
  position: absolute !important;
  top: 1074px !important;
  left: 206px !important;
}

.page-produit .carte-collab-2 {
  left: 35px !important;
}

.page-produit .carte-collab-3 {
  left: 43px !important;
}

.page-produit .carte-collab-4 {
  position: absolute !important;
  top: 1074px !important;
  left: 418px !important;
}

.page-produit .carte-collab-5 {
  position: absolute !important;
  top: 1210px !important;
  left: 180px !important;
}

.page-produit .video-2 {
  position: absolute !important;
  top: 1890px !important;
  left: 180px !important;
}

.page-produit .video-3 {
  position: absolute !important;
  top: 2545px !important;
  left: 467px !important;
}

.page-produit .carte-collab-6 {
  left: 20px !important;
}

.page-produit .carte-collab-7 {
  position: absolute !important;
  top: 1074px !important;
  left: 842px !important;
}

.page-produit .carte-collab-8 {
  left: 27px !important;
}

.page-produit .carte-collab-9 {
  left: 24px !important;
}

.page-produit .carte-collab-10 {
  position: absolute !important;
  top: 1074px !important;
  left: 1054px !important;
}

.page-produit .carte-collab-11 {
  left: 34px !important;
}

.page-produit .group-7 {
  width: 107px;
  height: 16px;
  position: absolute;
  top: 930px;
  left: 939px;
}

.page-produit .arrow-left {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.page-produit .arrow-left-instance {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 0 !important;
  left: 91px !important;
}

.page-produit .ellipse-6 {
  background-color: #d9d9d9;
  border-radius: 3.5px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 26px;
}

.page-produit .ellipse-7 {
  opacity: .5;
  background-color: #d9d9d9;
  border-radius: 3.5px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 42px;
}

.page-produit .ellipse-8 {
  opacity: .5;
  background-color: #d9d9d9;
  border-radius: 3.5px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 58px;
}

.page-produit .ellipse-9 {
  opacity: .5;
  background-color: #d9d9d9;
  border-radius: 3.5px;
  width: 7px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 74px;
}

.page-produit .text-wrapper-27 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  text-align: center;
  width: 214px;
  height: 52px;
  position: absolute;
  top: 803px;
  left: 294px;
}

.page-produit .text-wrapper-28 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  text-align: center;
  width: 214px;
  height: 52px;
  position: absolute;
  top: 803px;
  left: 932px;
}

.page-produit .text-wrapper-29 {
  color: var(--bleu-2);
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  text-align: center;
  width: 286px;
  height: 26px;
  position: absolute;
  top: 803px;
  left: 577px;
}

.page-produit .navbar {
  backdrop-filter: blur(24px) brightness();
  z-index: 100;
  background-color: #fffc;
  width: 1440px;
  height: 105px;
  position: fixed;
  top: 0;
}

.page-produit .group-8 {
  width: 147px;
  height: 55px;
  position: absolute;
  top: 28px;
  left: 60px;
}

.page-produit .text-wrapper-30 {
  color: var(--bleu-2);
  letter-spacing: 0;
  width: 250px;
  height: 100px;
  font-family: Nunito Sans, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: 35.9px;
  position: absolute;
  top: 38px;
  left: 262px;
}

.page-produit .text-wrapper-31 {
  color: var(--bleu-2);
  letter-spacing: 0;
  width: 103px;
  height: 49px;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.9px;
  position: absolute;
  top: 28px;
  left: 679px;
}

.page-produit .text-wrapper-32 {
  color: var(--bleu-2);
  letter-spacing: 0;
  width: 65px;
  height: 49px;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.9px;
  position: absolute;
  top: 40px;
  left: 1154px;
}

.page-produit .github-6 {
  width: 30px !important;
  height: 30px !important;
  position: absolute !important;
  top: 38px !important;
  left: 1250px !important;
}

.page-produit .language {
  position: absolute !important;
  top: 43px !important;
  left: 1320px !important;
}

.page-produit .frame-2 {
  align-items: center;
  gap: 5px;
  height: 49px;
  display: inline-flex;
  position: absolute;
  top: 29px;
  left: 468px;
}

.page-produit .text-wrapper-33 {
  color: var(--bleu-2);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Nunito Sans, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.9px;
  position: relative;
}

.page-produit .chevron-down {
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.page-produit .fiche-produit-instance {
  position: absolute !important;
  top: -30px !important;
  left: 524px !important;
}

.page-produit .fiche-produit-2 {
  background-color: unset !important;
  border-radius: unset !important;
  position: absolute !important;
  top: 200px !important;
  left: 40px !important;
}

.page-produit .fiche-produit-3 {
  background-color: unset !important;
  border-radius: unset !important;
  position: absolute !important;
  top: 200px !important;
  left: 207px !important;
}

.page-produit .fiche-produit-4 {
  top: -1087px !important;
  left: 2650px !important;
}

.page-produit .fiche-produit-5 {
  position: absolute !important;
  top: -30px !important;
  left: 100px !important;
}

.page-produit .fiche-produit-6 {
  background-color: var(--bleu-5) !important;
}

.page-produit .fiche-produit-7 {
  background-color: unset !important;
  border-radius: unset !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.page-produit .fiche-produit-8 {
  background-color: unset !important;
  border-radius: unset !important;
  position: absolute !important;
  top: 0 !important;
  left: 167px !important;
}

.page-produit .fiche-produit-9 {
  position: absolute !important;
  top: -30px !important;
  left: 948px !important;
}

.fiche-produit {
  width: 392px;
  height: 460px;
}

.fiche-produit .overlap-group-3 {
  position: relative;
}

.fiche-produit .frame-2 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 142px;
  display: inline-flex;
  position: absolute;
  top: 49px;
  left: 40px;
}

.fiche-produit .titre {
  color: #fff;
  font-family: var(--titre-h3-font-family);
  font-size: var(--titre-h3-font-size);
  font-style: var(--titre-h3-font-style);
  font-weight: var(--titre-h3-font-weight);
  letter-spacing: var(--titre-h3-letter-spacing);
  line-height: var(--titre-h3-line-height);
  width: 305px;
  position: relative;
}

.fiche-produit .titre-2 {
  color: #fff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  width: 292px;
  position: relative;
}

.fiche-produit .frame-3 {
  flex-direction: column;
  align-items: flex-start;
  height: 142px;
  display: inline-flex;
  position: absolute;
  top: 169px;
  left: 40px;
}

.fiche-produit .titre-3 {
  color: #fff;
  font-family: var(--titre-h3-font-family);
  font-size: var(--titre-h3-font-size);
  font-style: var(--titre-h3-font-style);
  font-weight: var(--titre-h3-font-weight);
  letter-spacing: var(--titre-h3-letter-spacing);
  line-height: var(--titre-h3-line-height);
  width: 305px;
  margin-top: 10px;
  position: relative;
}

.fiche-produit .produit-instance {
  position: absolute !important;
  top: 309px !important;
  left: 40px !important;
}

.fiche-produit .instance-node {
  width: 392px !important;
  height: 198px !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
}

.fiche-produit .overlap-group-4 {
  background-color: #060d30;
  border-radius: 5px;
  width: 392px;
  height: 378px;
  position: absolute;
  top: 139px;
  left: 0;
}

.fiche-produit .group-2 {
  width: 312px;
  height: 168px;
  position: absolute;
  top: 200px;
  left: 40px;
}

.fiche-produit .overlap-group-5 {
  width: 392px;
  position: absolute;
  left: 0;
}

.fiche-produit .frame-4 {
  flex-direction: column;
  align-items: flex-start;
  height: 182px;
  display: inline-flex;
  position: absolute;
  left: 40px;
}

.fiche-produit .secu {
  width: 392px;
  height: 288px;
  position: absolute;
  top: 0;
  left: 0;
}

.fiche-produit .overlap-group-6 {
  background-image: url("architecture-4.11a3af7d.svg");
  background-size: 100% 100%;
  height: 198px;
  position: relative;
  top: 90px;
}

.fiche-produit .vector {
  width: 189px;
  height: 183px;
  position: absolute;
  top: 0;
  left: 203px;
}

.fiche-produit .vector-2 {
  width: 194px;
  height: 165px;
  position: absolute;
  top: 0;
  left: 198px;
}

.fiche-produit .vector-3 {
  width: 92px;
  height: 101px;
  position: absolute;
  top: 5px;
  left: 160px;
}

.fiche-produit .vector-4 {
  width: 81px;
  height: 80px;
  position: absolute;
  top: 28px;
  left: 176px;
}

.fiche-produit .exclude {
  width: 119px;
  height: 61px;
  position: absolute;
  top: 0;
  left: 77px;
}

.fiche-produit .ellipse-5 {
  width: 117px;
  height: 58px;
  position: absolute;
  top: 0;
  left: 76px;
}

.fiche-produit .vector-5 {
  width: 51px;
  height: 49px;
  position: absolute;
  top: 5px;
  left: 160px;
}

.fiche-produit .produit-2 {
  position: absolute !important;
  top: 309px !important;
  left: 207px !important;
}

.fiche-produit.infrahover {
  background-color: var(--bleu-4);
  border-radius: 5px;
}

.fiche-produit.devopdefaut {
  position: relative;
}

.fiche-produit.devopshover, .fiche-produit.secuhover {
  background-color: var(--bleu-4);
  border-radius: 5px;
  position: relative;
}

.fiche-produit.secudefaut {
  position: relative;
}

.fiche-produit.infrahover .overlap-group-3 {
  height: 477px;
}

.fiche-produit.infrad-faut .overlap-group-3 {
  background-color: #060d30;
  border-radius: 5px;
  height: 378px;
  top: 139px;
}

.fiche-produit.devopdefaut .overlap-group-3, .fiche-produit.devopshover .overlap-group-3, .fiche-produit.secuhover .overlap-group-3, .fiche-produit.secudefaut .overlap-group-3 {
  height: 378px;
}

.fiche-produit.infrahover .overlap-group-5, .fiche-produit.infrad-faut .overlap-group-5, .fiche-produit.devopdefaut .overlap-group-5, .fiche-produit.devopshover .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.secuhover .overlap-group-5 {
  height: 391px;
  top: -90px;
}

.fiche-produit.secudefaut .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.infrahover .frame-4, .fiche-produit.infrad-faut .frame-4, .fiche-produit.devopdefaut .frame-4 {
  top: 229px;
}

.fiche-produit.devopshover .frame-4 {
  top: 139px;
}

.fiche-produit.secuhover .frame-4 {
  top: 285px;
}

.fiche-produit.secudefaut .frame-4 {
  top: 229px;
}

.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  -o-transition: all .25s ease-in;
  opacity: .4;
  filter: alpha(opacity= 40);
  z-index: 2;
  cursor: pointer;
  background: none;
  border: 0;
  font-size: 32px;
  transition: all .25s ease-in;
  position: absolute;
  top: 20px;
}

.carousel .control-arrow:focus, .carousel .control-arrow:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .control-arrow:before, .carousel.carousel-slider .control-arrow:before {
  content: "";
  border-top: 8px solid #0000;
  border-bottom: 8px solid #0000;
  margin: 0 5px;
  display: inline-block;
}

.carousel .control-disabled.control-arrow {
  opacity: 0;
  filter: alpha(opacity= 0);
  cursor: inherit;
  display: none;
}

.carousel .control-prev.control-arrow {
  left: 0;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid #fff;
}

.carousel .control-next.control-arrow {
  right: 0;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid #fff;
}

.carousel-root {
  outline: none;
}

.carousel {
  width: 100%;
  position: relative;
}

.carousel * {
  box-sizing: border-box;
}

.carousel img {
  pointer-events: none;
  width: 100%;
  display: inline-block;
}

.carousel .carousel {
  position: relative;
}

.carousel .control-arrow {
  background: none;
  border: 0;
  outline: 0;
  margin-top: -13px;
  font-size: 18px;
  top: 50%;
}

.carousel .thumbs-wrapper {
  margin: 20px;
  overflow: hidden;
}

.carousel .thumbs {
  -o-transition: all .15s ease-in;
  white-space: nowrap;
  list-style: none;
  transition: all .15s ease-in;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.carousel .thumb {
  -o-transition: border .15s ease-in;
  white-space: nowrap;
  border: 3px solid #fff;
  margin-right: 6px;
  padding: 2px;
  transition: border .15s ease-in;
  display: inline-block;
  overflow: hidden;
}

.carousel .thumb:focus {
  border: 3px solid #ccc;
  outline: none;
}

.carousel .thumb.selected, .carousel .thumb:hover {
  border: 3px solid #333;
}

.carousel .thumb img {
  vertical-align: top;
}

.carousel.carousel-slider {
  margin: 0;
  position: relative;
  overflow: hidden;
}

.carousel.carousel-slider .control-arrow {
  color: #fff;
  margin-top: 0;
  padding: 5px;
  font-size: 26px;
  top: 0;
  bottom: 0;
}

.carousel.carousel-slider .control-arrow:hover {
  background: #0003;
}

.carousel .slider-wrapper {
  -o-transition: height .15s ease-in;
  width: 100%;
  margin: auto;
  transition: height .15s ease-in;
  overflow: hidden;
}

.carousel .slider-wrapper.axis-horizontal .slider {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  flex-flow: column;
}

.carousel .slider-wrapper.axis-vertical {
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: flex;
}

.carousel .slider-wrapper.axis-vertical .slider {
  flex-direction: column;
}

.carousel .slider {
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.carousel .slider.animated {
  -o-transition: all .35s ease-in-out;
  transition: all .35s ease-in-out;
}

.carousel .slide {
  text-align: center;
  min-width: 100%;
  margin: 0;
  position: relative;
}

.carousel .slide img {
  vertical-align: top;
  border: 0;
  width: 100%;
}

.carousel .slide iframe {
  border: 0;
  width: calc(100% - 80px);
  margin: 0 40px 40px;
  display: inline-block;
}

.carousel .slide .legend {
  -o-transition: all .5s ease-in-out;
  color: #fff;
  text-align: center;
  opacity: .25;
  -o-transition: opacity .35s ease-in-out;
  background: #000;
  border-radius: 10px;
  width: 90%;
  margin-left: -45%;
  padding: 10px;
  font-size: 12px;
  transition: opacity .35s ease-in-out;
  position: absolute;
  bottom: 40px;
  left: 50%;
}

.carousel .control-dots {
  text-align: center;
  z-index: 1;
  width: 100%;
  margin: 10px 0;
  padding: 0;
  position: absolute;
  bottom: 0;
}

@media (width >= 960px) {
  .carousel .control-dots {
    bottom: 0;
  }
}

.carousel .control-dots .dot {
  -o-transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity= 30);
  cursor: pointer;
  background: #fff;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 0 8px;
  transition: opacity .25s ease-in;
  display: inline-block;
  box-shadow: 1px 1px 2px #000000e6;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  filter: alpha(opacity= 100);
}

.carousel .carousel-status {
  text-shadow: 1px 1px 1px #000000e6;
  color: #fff;
  padding: 5px;
  font-size: 10px;
  position: absolute;
  top: 0;
  right: 0;
}

.carousel:hover .slide .legend {
  opacity: 1;
}
/*# sourceMappingURL=index.9056941a.css.map */
