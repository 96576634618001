.fiche-produit {
  height: 460px;
  width: 392px;
}

.fiche-produit .overlap-group-3 {
  position: relative;
}

.fiche-produit .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 142px;
  justify-content: flex-start;
  left: 40px;
  position: absolute;
  top: 49px;
}

.fiche-produit .titre {
  color: #ffffff;
  font-family: var(--titre-h3-font-family);
  font-size: var(--titre-h3-font-size);
  font-style: var(--titre-h3-font-style);
  font-weight: var(--titre-h3-font-weight);
  letter-spacing: var(--titre-h3-letter-spacing);
  line-height: var(--titre-h3-line-height);
  position: relative;
  width: 305px;
}

.fiche-produit .titre-2 {
  color: #ffffff;
  font-family: var(--texte-regular-font-family);
  font-size: var(--texte-regular-font-size);
  font-style: var(--texte-regular-font-style);
  font-weight: var(--texte-regular-font-weight);
  letter-spacing: var(--texte-regular-letter-spacing);
  line-height: var(--texte-regular-line-height);
  position: relative;
  width: 292px;
}

.fiche-produit .frame-3 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 142px;
  left: 40px;
  position: absolute;
  top: 169px;
}

.fiche-produit .titre-3 {
  color: #ffffff;
  font-family: var(--titre-h3-font-family);
  font-size: var(--titre-h3-font-size);
  font-style: var(--titre-h3-font-style);
  font-weight: var(--titre-h3-font-weight);
  letter-spacing: var(--titre-h3-letter-spacing);
  line-height: var(--titre-h3-line-height);
  margin-top: 10px;
  position: relative;
  width: 305px;
}

.fiche-produit .produit-instance {
  left: 40px !important;
  position: absolute !important;
  top: 309px !important;
}

.fiche-produit .instance-node {
  height: 198px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 392px !important;
}

.fiche-produit .overlap-group-4 {
  background-color: #060d30;
  border-radius: 5px;
  height: 378px;
  left: 0;
  position: absolute;
  top: 139px;
  width: 392px;
}

.fiche-produit .group-2 {
  height: 168px;
  left: 40px;
  position: absolute;
  top: 200px;
  width: 312px;
}

.fiche-produit .overlap-group-5 {
  left: 0;
  position: absolute;
  width: 392px;
}

.fiche-produit .frame-4 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  height: 182px;
  left: 40px;
  position: absolute;
}

.fiche-produit .secu {
  height: 288px;
  left: 0;
  position: absolute;
  top: 0;
  width: 392px;
}

.fiche-produit .overlap-group-6 {
  background-image: url(../../../static/img/architecture-4.svg);
  background-size: 100% 100%;
  height: 198px;
  position: relative;
  top: 90px;
}

.fiche-produit .vector {
  height: 183px;
  left: 203px;
  position: absolute;
  top: 0;
  width: 189px;
}

.fiche-produit .vector-2 {
  height: 165px;
  left: 198px;
  position: absolute;
  top: 0;
  width: 194px;
}

.fiche-produit .vector-3 {
  height: 101px;
  left: 160px;
  position: absolute;
  top: 5px;
  width: 92px;
}

.fiche-produit .vector-4 {
  height: 80px;
  left: 176px;
  position: absolute;
  top: 28px;
  width: 81px;
}

.fiche-produit .exclude {
  height: 61px;
  left: 77px;
  position: absolute;
  top: 0;
  width: 119px;
}

.fiche-produit .ellipse-5 {
  height: 58px;
  left: 76px;
  position: absolute;
  top: 0;
  width: 117px;
}

.fiche-produit .vector-5 {
  height: 49px;
  left: 160px;
  position: absolute;
  top: 5px;
  width: 51px;
}

.fiche-produit .produit-2 {
  left: 207px !important;
  position: absolute !important;
  top: 309px !important;
}

.fiche-produit.infrahover {
  background-color: var(--bleu-4);
  border-radius: 5px;
}

.fiche-produit.devopdefaut {
  position: relative;
}

.fiche-produit.devopshover {
  background-color: var(--bleu-4);
  border-radius: 5px;
  position: relative;
}

.fiche-produit.secuhover {
  background-color: var(--bleu-4);
  border-radius: 5px;
  position: relative;
}

.fiche-produit.secudefaut {
  position: relative;
  /* top: 200px; */
}

.fiche-produit.infrahover .overlap-group-3 {
  height: 477px;
}

.fiche-produit.infrad-faut .overlap-group-3 {
  background-color: #060d30;
  border-radius: 5px;
  height: 378px;
  top: 139px;
}

.fiche-produit.devopdefaut .overlap-group-3 {
  height: 378px;
}

.fiche-produit.devopshover .overlap-group-3 {
  height: 378px;
}

.fiche-produit.secuhover .overlap-group-3 {
  height: 378px;
}

.fiche-produit.secudefaut .overlap-group-3 {
  height: 378px;
}

.fiche-produit.infrahover .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.infrad-faut .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.devopdefaut .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.devopshover .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.secuhover .overlap-group-5 {
  height: 391px;
  top: -90px;
}

.fiche-produit.secudefaut .overlap-group-5 {
  height: 281px;
  top: 0;
}

.fiche-produit.infrahover .frame-4 {
  top: 229px;
}

.fiche-produit.infrad-faut .frame-4 {
  top: 229px;
}

.fiche-produit.devopdefaut .frame-4 {
  top: 229px;
}

.fiche-produit.devopshover .frame-4 {
  top: 139px;
}

.fiche-produit.secuhover .frame-4 {
  top: 285px;
}

.fiche-produit.secudefaut .frame-4 {
  top: 229px;
}
