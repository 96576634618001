.bouton {
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 10px 30px;
  position: relative;
}

.bouton .en-savoir-plus {
  color: var(--bleu-2);
  font-family: "Nunito Sans", Helvetica;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 25.9px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
